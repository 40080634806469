<template>
    <div class="test-detail">
        <info-panel :info-title='ergoregion.title'
            v-for="ergoregion in evaluationDetail" :key="ergoregion.title">
            <div class="test-detail-container" >
                <div class="test-detail-content">
                    <div class="test-detail-item"
                        v-for="(item, index) in ergoregion.evaluationDetail" :key="index" >
                        <div class="month-age" >
                            <span>{{item.monthAge}}月</span>
                        </div>
                        <div class="question-title">
                            <el-tooltip placement="top">
                                <div slot="content" class="question-completion">
                                    <p>方式：{{item.method}}</p>
                                    <p>要求：{{item.ask}}</p>
                                </div>
                                <span>{{item.name}}</span>
                            </el-tooltip>
                        </div>
                        <div class="question-status" :class="item.isStandard ? 'pass' : 'notPass' ">
                            <span>{{item.isStandard | testDetailStatusFilter}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </info-panel>
    </div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        InfoPanel: () => import('@/components/InfoPanel'),
    },
    props: {
        id: {
            type: [String,Number],
            required: true,
        }
    },
    data() {
        return {
            evaluationDetail: [
                {
                    title: '大运动',
                    evaluationDetail: [],
                },
                {
                    title: '社会行为',
                    evaluationDetail: [],
                },
                {
                    title: '语言',
                    evaluationDetail: [],
                },
                {
                    title: '适应能力',
                    evaluationDetail: [],
                },
                {
                    title: '精细运动',
                    evaluationDetail: [],
                }
            ],
        }
    },
    filters: {
        testDetailStatusFilter(val) {
            let statuSrc = '';
            switch (val) {
                case false:
                    statuSrc = '不通过';
                    break;
                case true:
                    statuSrc = '通过';
                    break;
                default:
                    break;
            }
            return statuSrc;
        },
    },
    created() {
        this.getEvaluationDetail();
    },
    methods: {
        getEvaluationDetail() {  // 答题详情
            if (parseInt(this.id) > 0) {
                Core.operationApi.Record.evaluationDetail(this.id).then((res) => {
                    let questionBank = JSON.parse(res.detail.data).form
                    let questionList = questionBank.filter((item) => {
                        return item.isStandard !== undefined
                    })
                    console.log("getEvaluationDetail -> questionList", questionList)
                    questionList.forEach((item) => {
                        switch (item.type) {
                            case 1:
                                this.evaluationDetail[0].evaluationDetail.push(item)
                                break;
                            case 2:
                                this.evaluationDetail[1].evaluationDetail.push(item)
                                break;
                            case 3:
                                this.evaluationDetail[2].evaluationDetail.push(item)
                                break;
                            case 4:
                                this.evaluationDetail[3].evaluationDetail.push(item)
                                break;
                            case 5:
                                this.evaluationDetail[4].evaluationDetail.push(item)
                                break;
                        }
                    })
                    console.log("getEvaluationDetail -> this.evaluationDetail", this.evaluationDetail)
                });
            } else {
                return this.$message.warning("用户未答题")
            }
        },
    }
}
</script>

<style lang='scss'>
    .test-detail {
        &-container {
            padding: 22px 15px 10px;
        }
        &-content {
            width: 100%;
            position: relative;
            @include flex(row, flex-start, flex-start);
            flex-wrap: wrap;
            &::before, &::after {
                content: '';
                height: 100%;
                width: 1px;
                background-color: #E7ECF1;
                position: absolute;
                top: 0;
            }
            &::before {
                left: 33.3333333%;
            }
            &::after {
                right: 33.3333333%;
            }
        }
        .test-detail-item {
            @include flex(row, center, center);
            // flex: 1;
            width: calc(100% / 3);
            font-size: 14px;
            margin-bottom: 12px;
            box-sizing: border-box;
            padding: 0 2%;
            .month-age, .question-title, .question-status {
                height: 32px;
                line-height: 32px;
                box-sizing: border-box;
                text-align: center;

                color: #7C8489;
                border: 1px solid #DCE2E9;
            }
            .month-age {
                width: 42px;
                border-radius: 3px 0 0 3px;
            }
            .question-title {
                border-radius: 0 3px 3px 0;
                width: calc(100% - 130px);
                margin-right: 16px;
                border-left: 0;
                text-align: left;
                padding: 0 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .question-status {
                border-radius: 3px;
                width: 72px;
                border: 1px solid transparent;
                text-align: center;
                &.pass {
                    color: #06B552;
                    border-color: #06B552;
                }
                &.notPass {
                    color: #EF8531;
                    border-color: #EF8531;
                }
            }
        }
        @media (max-width: 1200px) {
            .test-detail-content {
                &::after {
                    width: 0px;
                }
                &::before{
                    left: 50%;
                }
                .test-detail-item {
                    width: 50%;
                }
            }
        }
        @media (max-width: 800px) {
            .test-detail-content {
                &::before{
                    width: 0px;
                }
                .test-detail-item {
                    width: 100%;
                }
            }
        }
    }
    .question-completion {
        max-width: 300px;
        p {
            margin-bottom: 7px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>